import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Perspectives from 'components/article/Perspectives';
import Sources from 'components/article/Sources';
import Summary from 'components/article/Summary';
import useABTesting from 'hooks/useABTesting';
import usePosts from 'hooks/usePosts';
import { useAtom } from 'jotai';
import { pushModal, postsOpened as postsOpenedAtom } from '@utils/store';
import { App } from '@layouts';
import { Box, Container, Stack, Tab, Tabs } from '@mui/material';
import { language } from 'utils/i18n';
import { event as analyticsEvent } from 'utils/analytics';
import { share as sharePost } from 'utils/browser';
import { PostLoader, Post } from '@components';
import { Link, navigate } from 'gatsby';
import BackPage from 'components/BackPage';
import useBookmarks from 'hooks/useBookmarks';
import useUser from 'hooks/useUser';
import ShareIcon from '@images/share.svg';
import BookmarkIcon from '@images/bookmark.svg';
import BookmarkFillIcon from '@images/bookmarkFill.svg';
import RowLogo from '@images/row_logo.svg';
import HeaderLoader from 'components/loading/HeaderLoader';
import { useMemo } from 'react';

// PostPage component
function PostPage({ id }) {
  const { t } = useABTesting({}, 'pages.post');
  const { session } = useUser();
  const {
    posts,
    fetchPost: { mutate: getPost },
  } = usePosts();
  const {
    bookmarks,
    remove: removeBookmark,
    add: addBookmark,
  } = useBookmarks();
  const [postOpened, setPostsOpened] = useAtom(postsOpenedAtom);
  const [_, setModal] = useAtom(pushModal);
  const [step, setStep] = useState(0);
  const post = posts[id];
  const isBookmarked = bookmarks.includes(Number(id));
  let isPositiveSunday = post?.topic?.id === 28;

  // Intersection observers for scrolling
  const { ref: refSummary, inView: inViewSummary } = useInView({
    threshold: 0.2,
  });
  const { ref: refPerspectives, inView: inViewPerspectives } = useInView({
    threshold: 0.4,
  });
  const { ref: refSources, inView: inViewSources } = useInView({
    threshold: 0.4,
  });

  // Tabs configuration
  const tabs = ['summary', 'perspectives', 'sources'];

  // Event handler for tab change
  const handleTabsChange = (e, newValue) => {
    const currentTab = tabs[newValue];
    analyticsEvent(`scroll_${currentTab}`, { post: id });
    const section = document.getElementById(currentTab);
    const scrollableDiv = document.getElementById('scrollableDiv');
    if (section) {
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition - 120;
      scrollableDiv.scrollBy({
        top: offsetPosition,
        behavior: 'smooth',
      });
    } else {
      navigate(`#${currentTab}`);
    }
  };

  // Function to choose the bookmark icon based on the bookmark status
  const handleBookmarkIcon = useMemo(() => {
    return isBookmarked ? BookmarkFillIcon : BookmarkIcon;
  }, [isBookmarked]);

  // Event handler for bookmarking/unbookmarking a post
  const handleBookmark = () => {
    if (!session) {
      navigate('/sign-in');
      return;
    }
    if (isBookmarked) {
      removeBookmark.mutate(id);
    } else {
      addBookmark.mutate(id);
    }
  };

  // Event handler for sharing a post
  const handleShare = () => {
    sharePost(post);
    analyticsEvent('push_modal', { trigger: 'share_post' });
    setModal(true);
  };

  // Memoize post title and image to avoid recalculation on every render
  const postTitle = useMemo(() => {
    if (!post) return '';
    const { summary, media } = post;
    if (summary) {
      if (summary[0]?.title) return summary[0].title[language];
    } else if (media) {
      if (media[0].texts) return media[0].texts[language];
    }
    return '';
  }, [post]);

  const postImage = useMemo(() => {
    if (!post) return '';
    const { summary } = post;
    return summary[0]?.media?.url;
  }, [post]);

  // Event handler for post refresh
  const onRefresh = async (resolve) => {
    await getPost(id);
    return resolve();
  };

  // Fetch the post data on component mount
  useEffect(() => {
    getPost(id);
  }, [getPost]);

  // Track which section is currently in view
  useEffect(() => {
    if (inViewSummary && step !== 0) {
      setStep(0);
    } else if (inViewPerspectives && step !== 1) {
      setStep(1);
    } else if (inViewSources && step !== 2) {
      setStep(2);
    }
  }, [inViewSummary, inViewPerspectives, inViewSources]);

  // Track the post opening and trigger the modal if needed
  useEffect(() => {
    return () => {
      if (postOpened.indexOf(id) === -1) {
        if (postOpened.length + 1 === 2) {
          setModal(true);
          analyticsEvent('push_modal', { trigger: 'post_exit' });
        }
        setPostsOpened([...postOpened, id]);
      }
    };
  }, []);

  // JSX for the header
  const customHeader = (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      {post ? (
        <>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            height="3.5rem"
          >
            {session ? (
              <BackPage backText={t('backPage.backText')} />
            ) : (
              <Link to="/">
                <Box
                  component="img"
                  src={RowLogo}
                  alt="The Newsroom"
                  width="100%"
                  height="2.25rem"
                />
              </Link>
            )}
            <Stack flexDirection="row">
              <Box
                onClick={handleShare}
                component="img"
                src={ShareIcon}
                alt="share"
                sx={{
                  height: '1.063rem',
                  objectFit: 'contain',
                  cursor: 'pointer',
                  marginRight: '1.114rem',
                }}
              />
              <Box
                onClick={handleBookmark}
                component="img"
                src={handleBookmarkIcon}
                alt="bookmark"
                sx={{
                  height: '1.063rem',
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
              />
            </Stack>
          </Stack>
          {!isPositiveSunday && (
            <Tabs
              value={step}
              variant="fullWidth"
              sx={{
                minHeight: '2rem',
              }}
              centered
              onChange={handleTabsChange}
            >
              <Tab
                sx={{
                  fontWeight: 600,
                  textTransform: 'initial',
                  fontSize: '1rem',
                  minHeight: '2rem',
                  pt: 0,
                }}
                disableTouchRipple
                wrapped
                label={t('tabs.consensus')}
              />
              <Tab
                sx={{
                  fontWeight: 600,
                  textTransform: 'initial',
                  fontSize: '1rem',
                  minHeight: '2rem',
                  pt: 0,
                }}
                disableTouchRipple
                wrapped
                label={t('tabs.perspectives')}
              />
              <Tab
                sx={{
                  fontWeight: 600,
                  textTransform: 'initial',
                  fontSize: '1rem',
                  minHeight: '2rem',
                  pt: 0,
                }}
                disableTouchRipple
                wrapped
                label={t('tabs.sources')}
              />
            </Tabs>
          )}
        </>
      ) : (
        <HeaderLoader />
      )}
    </Stack>
  );

  return (
    <App
      titleSEO={postTitle}
      imageSEO={postImage}
      onRefresh={onRefresh}
      customHeader={customHeader}
      customHeaderHeight={'88px'}
    >
      <Container maxWidth="sm">
        {post ? (
          <Stack>
            {isPositiveSunday ? (
              <Post {...post} />
            ) : (
              <>
                <Summary
                  innerRef={refSummary}
                  summary={post.summary}
                  author={post.author}
                />
                <Perspectives
                  innerRef={refPerspectives}
                  perspectives={post.other_perspectives}
                />
                <Sources
                  innerRef={refSources}
                  politicalBars={post.political_breakdown}
                  articles={post.articles}
                />
              </>
            )}
          </Stack>
        ) : (
          <Stack sx={{ pt: 2 }}>
            <PostLoader />
          </Stack>
        )}
      </Container>
    </App>
  );
}

export default React.memo(PostPage);
