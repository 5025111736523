import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { politicalBackground, politicalColor } from 'utils/colors';

/**
 * PoliticalFilter Component
 *
 * @param {string} type - The type of political background (e.g., 'left', 'right', 'center').
 * @param {string} text - The text to be displayed inside the filter.
 * @param {function} handleClick - The click event handler for the filter.
 * @param {boolean} active - Indicates if the filter is currently active.
 * @returns {JSX.Element} A JSX element representing the PoliticalFilter component.
 */
export default function PoliticalFilter({ type, text, handleClick, active }) {
  return (
    <Box
      onClick={() => handleClick(type)}
      sx={{
        width: '33.33%',
        cursor: 'pointer',
        border: '1px solid #C7C7C7',
        borderLeft: 'none',
        backgroundColor: politicalBackground(type),
        // Apply an inset shadow if the filter is active
        boxShadow: active ? `inset 0 0 1px ${politicalColor(type)}` : 'none',
        '&:first-of-type': {
          borderRadius: '6px 0px 0px 6px',
          borderLeft: '1px solid #C7C7C7',
        },
        '&:last-child': {
          borderRadius: '0px 6px 6px 0px',
        },
      }}
    >
      <Typography
        variant="text"
        component="p"
        sx={{
          fontWeight: 500,
          p: '0.25rem',
          textAlign: 'center',
          color: politicalColor(type),
          // Apply bold font weight if the filter is active
          fontWeight: active ? '600' : 'normal',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
