import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import AccordionCustom from './AccordionCustom';
import PoliticalBar from './PoliticalBar';
import SectionTitle from './SectionTitle';
import SourcesLogo from '@images/sources.svg';
import PublisherLogo from '../PublisherLogo';
import PoliticalFilter from './PoliticalFilter';
import useABTesting from 'hooks/useABTesting';

/**
 * Sources Component
 *
 * @param {Object} innerRef - React ref to be assigned to the component's container.
 * @param {Object} politicalBars - Object containing the political bar values for 'left', 'center', and 'right'.
 * @param {Array} articles - Array of articles data to be displayed.
 * @returns {JSX.Element} A JSX element representing the Sources component.
 */
export default function Sources({ innerRef, politicalBars, articles }) {
  const { t } = useABTesting({}, 'pages.post.sources');
  const { left, center, right } = politicalBars;
  const articlesLength = articles.length;
  const [politcalMaxWidth, setPolitcalMaxWidth] = useState(100);
  const [articlesFilter, setArticlesFilter] = useState('all');

  /**
   * Function to handle the articles filter by political type.
   * If the type matches the current filter, it resets the filter to 'all', otherwise, it sets it to the matched type.
   * @param {string} type - The political type ('left', 'center', or 'right') to filter articles.
   */
  function handleArticlesFilter(type) {
    if (type === articlesFilter) {
      setArticlesFilter('all');
    } else {
      setArticlesFilter(type);
    }
  }

  useEffect(() => {
    // Calculate the maximum width of the political bars to be used for bar scaling
    setPolitcalMaxWidth(Math.max(left, center, right) * 100 || 100);
  }, [left, center, right]);

  return (
    <Stack ref={innerRef} width="100%" id="sources" flexDirection="column">
      <SectionTitle
        title={t('sources_title')}
        subtitle={t('sources_text')}
        leftLogo
      >
        <Box
          component="img"
          src={SourcesLogo}
          alt="sources"
          sx={{ height: '3.125rem', objectFit: 'contain' }}
        />
      </SectionTitle>
      <Stack flexDirection="column">
        {/* Political Bars */}
        <Stack flexDirection="column" marginBottom="3.688rem">
          <Typography
            component="h4"
            variant="textBold"
            color="#2C2D42"
            marginBottom="0.375rem"
          >
            {t('political_title')}
          </Typography>
          <Typography variant="text" component="p" marginBottom="1.25rem">
            {t('political_text')}
          </Typography>
          <PoliticalBar
            type="left"
            value={left}
            maxWidth={politcalMaxWidth}
            articlesLength={articlesLength}
            text={t('left')}
          />
          <PoliticalBar
            type="center"
            value={center}
            maxWidth={politcalMaxWidth}
            articlesLength={articlesLength}
            text={t('center')}
          />
          <PoliticalBar
            type="right"
            value={right}
            maxWidth={politcalMaxWidth}
            articlesLength={articlesLength}
            text={t('right')}
          />
        </Stack>
        {/* Highlighted Articles */}
        <Stack flexDirection="column" marginBottom="3.688rem">
          <Typography
            component="h4"
            variant="textBold"
            color="#2C2D42"
            marginBottom="0.375rem"
          >
            {t('highlight_title')}
          </Typography>
          <Typography component="p" variant="text" marginBottom="1.25rem">
            {t('highlight_text')}
          </Typography>
          <Stack direction="column">
            {articles &&
              articles?.map(
                (
                  {
                    article: {
                      safe_url,
                      title,
                      publisher: { logo, name },
                    },
                    type,
                    highlighted,
                  },
                  index,
                ) => {
                  return (
                    highlighted === true && (
                      <PublisherLogo
                        key={`${name}-${index}`}
                        type={type}
                        title={title}
                        name={name}
                        logo={logo}
                        safeUrl={safe_url}
                      />
                    )
                  );
                },
              )}
          </Stack>
        </Stack>

        {/* Accordion with Filtered Articles */}
        <AccordionCustom title={t('accordion_title')}>
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="flex-start"
          >
            <Typography marginBottom="1.25rem">
              {t('accordion_text')}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              width="100%"
              marginBottom="1.25rem"
            >
              <PoliticalFilter
                type="left"
                text={t('left')}
                handleClick={handleArticlesFilter}
                active={articlesFilter === 'left'}
              />
              <PoliticalFilter
                type="center"
                text={t('center')}
                handleClick={handleArticlesFilter}
                active={articlesFilter === 'center'}
              />
              <PoliticalFilter
                type="right"
                text={t('right')}
                handleClick={handleArticlesFilter}
                active={articlesFilter === 'right'}
              />
            </Stack>
            {articles?.map(
              (
                {
                  article: {
                    safe_url,
                    publisher: { logo, name },
                  },
                  type,
                },
                index,
              ) =>
                articlesFilter === 'all' ? (
                  <PublisherLogo
                    key={`${name}-${index}`}
                    type={type}
                    title={name}
                    name={name}
                    logo={logo}
                    safeUrl={safe_url}
                  />
                ) : articlesFilter === String(type) ? (
                  <PublisherLogo
                    key={`${name}-${index}`}
                    type={type}
                    title={name}
                    name={name}
                    logo={logo}
                    safeUrl={safe_url}
                  />
                ) : null,
            )}
          </Stack>
        </AccordionCustom>
      </Stack>
    </Stack>
  );
}
