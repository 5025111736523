import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { language } from '@utils/i18n';
import SectionTitle from './SectionTitle';
import ConsensusLogo from '@images/consensus.svg';
import { getRandomColor } from 'utils/colors';
import useABTesting from 'hooks/useABTesting';
import { formatDate } from 'utils/date';

/**
 * Summary Component
 *
 * @param {Object} innerRef - React ref to be assigned to the component's container.
 * @param {Array} summary - Array of summary data to be displayed.
 * @param {Array} author - Array of author data to be displayed.
 * @returns {JSX.Element} A JSX element representing the Summary component.
 */
export default function Summary({ innerRef, summary, author }) {
  const { t } = useABTesting({}, 'pages.post.summary');
  const { media, created_at, title, text } = summary[0];
  const dateFormatted = formatDate(created_at, 1);

  return (
    <Stack ref={innerRef} id="summary" direction="column">
      {media && (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            minHeight: '25vh',
            margin: '0 auto 1.375rem auto',
          }}
        >
          <Box
            sx={{
              height: '40vh',
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {media.type === 'image' ? (
              <Box
                component="img"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                src={media.url}
                alt={media.url}
              />
            ) : (
              <Box
                component="video"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                autoPlay
                loop
                muted
              >
                <source src={media.url} type="video/mp4"></source>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {dateFormatted ? (
        <Typography component="p" variant="text" lineHeight="140%">
          {dateFormatted}
        </Typography>
      ) : null}
      {title ? (
        <Typography
          component="h2"
          sx={{
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.5rem',
            lineHeight: '1.938rem',
            marginBottom: '0.5rem',
          }}
        >
          {title[language]}
        </Typography>
      ) : null}
      <SectionTitle
        title={t('summary_title')}
        subtitle={t('summary_text')}
        leftLogo
      >
        <Box
          component="img"
          src={ConsensusLogo}
          alt="perspectives"
          sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      </SectionTitle>

      {/* Display Summary Text */}
      {text ? (
        <Stack direction="column">
          {text[language]?.split('/n').map((text, index) => (
            <Typography
              key={`${index}_text`}
              variant="text"
              component="p"
              sx={{
                marginBottom: '0.5rem',
                '&:last-child': {
                  marginBottom: 0,
                },
              }}
            >
              {text}
            </Typography>
          ))}
        </Stack>
      ) : null}

      {/* Display Authors */}
      <Stack direction="row" marginTop="1rem">
        {author ? (
          <Typography component="p" variant="text">
            {t('reviewBy')}
          </Typography>
        ) : null}
        {author
          ? author.map(({ author: { name, image, link_to_bio } }, index) => (
              <Stack
                key={`${index}_author`}
                direction="row"
                alignItems="center"
                onClick={link_to_bio ? () => navigate(link_to_bio) : () => {}}
                sx={{ cursor: link_to_bio ? 'pointer' : 'default' }}
              >
                {image ? (
                  <Box
                    component="img"
                    src={image}
                    alt={name}
                    sx={{
                      width: '1.75rem',
                      height: '1.75rem',
                      objectFit: 'cover',
                      borderRadius: '0.209rem',
                      marginLeft: '0.625rem',
                    }}
                  />
                ) : name ? (
                  <Box
                    sx={{
                      width: '1.75rem',
                      height: '1.75rem',
                      backgroundColor: getRandomColor(),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '0.209rem',
                    }}
                  >
                    <Typography
                      component="p"
                      variant="text"
                      sx={{
                        fontWeight: 600,
                        color: '#ffffff',
                      }}
                    >
                      {name[0]}
                    </Typography>
                  </Box>
                ) : null}
                {name ? (
                  <Typography
                    component="p"
                    variant="textBold"
                    sx={{
                      marginLeft: '0.625rem',
                      fontSize: '0.938rem',
                    }}
                  >
                    {name}
                  </Typography>
                ) : null}
              </Stack>
            ))
          : null}
      </Stack>
      <Typography
        component="p"
        variant="text"
        fontSize="0.75rem"
        marginTop="0.25rem"
      >
        {t('summaryAi')}
      </Typography>
    </Stack>
  );
}
