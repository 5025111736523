import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { language } from 'utils/i18n';
import AccordionCustom from './AccordionCustom';
import SectionTitle from './SectionTitle';
import PerspectivesLogo from '@images/perspectives.svg';
import useABTesting from 'hooks/useABTesting';
import DividerBasic from 'components/divider/DividerBasic';

/**
 * The Perspectives component is responsible for displaying two perspectives on a given topic.
 *
 * @param {Object} props - Component props.
 * @param {React.Ref} props.innerRef - Reference for the component.
 * @param {Array} props.perspectives - Array of perspectives data to display.
 *
 * @returns {JSX.Element} JSX element representing the Perspectives component.
 */
export default function Perspectives({ innerRef, perspectives }) {
  const { t } = useABTesting({}, 'pages.post.perspectives');

  const {
    perspective1_title,
    perspective1_text,
    perspective2_title,
    perspective2_text,
  } = perspectives[0];

  return (
    <Stack
      ref={innerRef}
      id="perspectives"
      minWidth="100%"
      paddingBottom="3.688rem"
    >
      <DividerBasic margin="3.688rem 0" />
      <SectionTitle
        title={t('perspectives_title')}
        subtitle={t('perspectives_text')}
        leftLogo
      >
        <Box
          component="img"
          src={PerspectivesLogo}
          alt="perspectives logo"
          sx={{
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </SectionTitle>

      <Stack flexDirection="column" spacing={2.5}>
        {/* Check if data for both perspectives is available */}
        {perspective1_title[language]?.length &&
        perspective1_text[language]?.length &&
        perspective2_title[language]?.length &&
        perspective2_text[language]?.length ? (
          <>
            <AccordionCustom
              title={perspective1_title[language]}
              texts={perspective1_text[language]}
              type="green"
            />
            <AccordionCustom
              title={perspective2_title[language]}
              texts={perspective2_text[language]}
              type="orange"
            />
          </>
        ) : (
          // If data is not available for both perspectives, display a message
          <Typography component="h4" variant="textBold" color="#2C2D42">
            {t('perspectives_none')}
          </Typography>
        )}
      </Stack>
      <DividerBasic margin="3.688rem 0 0 0" />
    </Stack>
  );
}
