import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/system';

// Define styles for different types of accordions
const typeStyles = {
  green: {
    background: '#ffffff',
    borderRadius: '0.375rem',
    border: '2px solid #83ca62',
  },
  orange: {
    background: '#ffffff',
    borderRadius: '0.375rem',
    border: '2px solid #f7cf5d',
  },
  default: {
    background: '#f7f7f7',
    borderRadius: 0,
    border: 'none',
  },
};

// Function to get styles based on the type prop
function getStyles(type) {
  return typeStyles[type] || typeStyles.default;
}

/**
 * The AccordionCustom component displays an accordion with customizable styles and content.
 *
 * @param {Object} props - Component props.
 * @param {string} props.title - The title of the accordion.
 * @param {string} props.subtitle - The subtitle of the accordion.
 * @param {Array} props.texts - An array of text items to be displayed in the accordion body.
 * @param {ReactNode} props.children - Additional content to be displayed in the accordion body.
 * @param {string} props.type - The type of the accordion, determining its color and style.
 *
 * @returns {JSX.Element} JSX element representing the AccordionCustom component.
 */
const AccordionCustom = ({ title, subtitle, texts, children, type }) => {
  // Extract styles based on the type prop
  const { background, borderRadius, border } = getStyles(type);

  // Check if the accordion should be disabled
  const isDisabled = texts?.length === 0 || (!texts && !children);

  return (
    <Accordion
      disableGutters
      disabled={isDisabled}
      style={{
        borderRadius,
        border,
        backgroundColor: background,
        marginBottom: 0,
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          '&.Mui-disabled': {
            opacity: '1',
          },
        }}
        // Render expandIcon only if the accordion is not disabled
        expandIcon={
          isDisabled ? null : (
            <ArrowDropDownIcon sx={{ fontSize: '3rem', fill: '#000000' }} />
          )
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography component="h4" variant="textBold" color="#2C2D42">
            {title}
          </Typography>
          {subtitle && <Typography>{subtitle}</Typography>}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 0,
        }}
      >
        {texts?.length > 0 &&
          texts.map((text, index) => (
            <Typography key={index} variant="text">
              {text}
            </Typography>
          ))}
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionCustom;
