import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { politicalBackground, politicalColor } from 'utils/colors';

/**
 * PoliticalBar Component
 *
 * @param {string} type - The type of political background (e.g., 'left', 'right', 'center').
 * @param {number} value - The value used to calculate the bar length.
 * @param {number} articlesLength - The total number of articles used for calculating the article number.
 * @param {string} text - The text to be displayed next to the political bar.
 * @param {number} maxWidth - The maximum width of the political bar in percentage (0 to 100).
 * @returns {JSX.Element} A JSX element representing the PoliticalBar component.
 */
export default function PoliticalBar({
  type,
  value,
  articlesLength,
  text,
  maxWidth,
}) {
  // Calculate the number of articles represented by the bar
  const articleNumber = Math.round((value * 100 * articlesLength) / 100);

  // Calculate the length of the political bar based on the provided value and maximum width
  const barLength = Math.ceil((((100 * value) / maxWidth) * 100) / 5) * 5;

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '0.375rem',
        '&:last-child': {
          marginBottom: 0,
        },
      }}
    >
      <Stack width="70%">
        <Box
          sx={{
            position: 'relative',
            height: '1.625rem',
            width: `calc(${
              barLength === 0 ? '0.75rem' : barLength + '%'
            } - 0.5rem)`, // Calculate the width of the political bar dynamically
            backgroundColor: politicalBackground(type),
          }}
        >
          <Typography
            component="span"
            variant="text"
            sx={{
              fontWeight: 500,
              color: politicalColor(type),
              position: 'absolute',
              top: '0.219rem',
              bottom: '0.219rem',
              right: barLength === 0 ? '-0.75rem' : '0.219rem',
            }}
          >
            {articleNumber}
          </Typography>
        </Box>
      </Stack>
      <Typography
        variant="text"
        component="p"
        sx={{
          fontWeight: 500,
          width: '30%',
          textAlign: 'right',
          color: politicalColor(type),
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
}
