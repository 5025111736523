import React from 'react';
import BackArrow from '@images/backArrow.svg';
import { Box, Stack, Typography } from '@mui/material';
import { navigate } from 'gatsby';

export default function BackPage({ backText }) {
  const goBack = () => {
    if (typeof window !== 'undefined' && window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      sx={{
        cursor: 'pointer',
      }}
      onClick={goBack}
    >
      <Box
        component="img"
        src={BackArrow}
        alt="back"
        sx={{
          fontSize: '1.063rem',
          fill: '#7d7d7d',
        }}
      />
      {backText ? (
        <Typography
          component="h3"
          sx={{
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '0.938rem',
            lineHeight: '1.25rem',
            marginLeft: '0.625rem',
          }}
        >
          {backText}
        </Typography>
      ) : null}
    </Stack>
  );
}
