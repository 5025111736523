import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { browserColors } from './colors';
import { language } from '@utils/i18n';
import { event } from './analytics';

// const URL = 'https://thenewsroomai.netlify.app';
const URL = 'https://app.thenewsroom.ai';

export const open = async (url) => {
  let urlFixed = url.startsWith('http') ? url : `https://${url}`;
  event('open_url', { url });
  await Browser.open({ url: urlFixed, toolbarColor: browserColors() });
};

export const share = async (post) => {
  const { id, summary, media } = post;
  const url = `${URL}/post/${id}`;
  let title = '';
  if (summary && summary[0] && summary[0].title) {
    title = summary[0].title[language];
  } else {
    title = media[0].texts[language];
  }
  if (!title) {
    const { topic } = post;
    if (topic && topic.id === 28) {
      // Positive Sundays
      title = `The Newsroom - ${topic.titles[language]}`;
    }
  }
  if (!title) {
    return;
  }
  event('share_url', { url });
  const isBrowser = Capacitor.getPlatform() === 'web';
  if (isBrowser) {
    await Clipboard.write({ url });
    alert('URL copied to clipboard');
  } else {
    await Share.share({
      title: 'Check this article on The Newsroom',
      text: title,
      url,
    });
  }
};
