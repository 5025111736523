import React from 'react';

export default function DividerBasic({ size, color, margin }) {
  return (
    <hr
      style={{
        borderTop: `${size || '1px'}`,
        borderColor: `${color || '#CDCDCD'}`,
        margin: margin,
      }}
    />
  );
}
