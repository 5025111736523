import { Typography, Link, Box } from '@mui/material';
import React from 'react';
import { open } from '@utils/browser';
import { politicalBackground } from 'utils/colors';

export default function PublisherLogo({ name, type, logo, title, safeUrl }) {
  return (
    <Link
      sx={{
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 1,
        width: '100%',
        '&:last-child': {
          marginBottom: 0,
        },
      }}
      onClick={() => {
        open(safeUrl);
      }}
    >
      {logo ? (
        <Box
          component="img"
          src={logo}
          alt={name}
          loading="lazy"
          sx={{
            height: '3.125rem',
            width: '6.25rem',
            objectFit: 'contain',
            border: '1px solid #d9d9d9',
            backgroundColor: politicalBackground(type),
            padding: '0.2rem',
            borderRadius: '0.209rem',
          }}
        />
      ) : (
        <Box
          sx={{
            height: '3.125rem',
            width: '6.25rem',
            objectFit: 'contain',
            border: '1px solid #d9d9d9',
            backgroundColor: politicalBackground(type),
            padding: '0.2rem',
            borderRadius: '0.209rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="textBold" component="p" color="#000000">
            {name[0]}
          </Typography>
        </Box>
      )}
      <Typography
        sx={{ width: '75%', paddingLeft: 1 }}
        component="p"
        variant="text"
        fontWeight={500}
        color={'#000000'}
      >
        {title}
      </Typography>
    </Link>
  );
}
