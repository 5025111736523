import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Stack } from '@mui/material';

const HeaderLoader = () => (
  <Stack
    direction="column"
    spacing={1}
    height="5.5rem"
    justifyContent="center"
    alignContent="space-evenly"
  >
    <Skeleton
      sx={{
        width: '100%',
        height: '1.5rem',
        borderRadius: '0.25rem',
      }}
      variant="rectangular"
    />
    <Skeleton
      sx={{
        width: '100%',
        height: '2rem',
        borderRadius: '0.25rem',
      }}
      variant="rectangular"
    />
  </Stack>
);

export default HeaderLoader;
